import React from "react"

const Course = () => {
  return <div>Course</div>
}

export default Course

// import React, { useState, useEffect } from "react"
// import { graphql } from "gatsby"
// import Layout from "@layouts/layout"
// import {
//   Banner,
//   Block,
//   Button,
//   Content,
//   Grid,
//   Image,
//   LessonList
// } from "@components"
// // import { useAuth } from "gatsby-theme-auth0"
// import { HelmetDatoCms } from "gatsby-source-datocms"
// import { isOnList, addToList } from "@helpers/autopilotHelpers"
// import Registered from "@svgs/registered.svg"
// import isPast from "date-fns/isPast"

// const Course = ({
//   data: { course, lessons, testlessons },
//   pageContext: { title, parent }
// }) => {
//   const {
//     id,
//     bannerImage,
//     bannerVideo,
//     booklet,
//     bookletLink,
//     image,
//     instructor,
//     listId,
//     planner,
//     closingContentNode,
//     contentNode,
//     instructionsNode,
//     introNode,
//     registrationCloseDate
//   } = course

//   const [registration, setRegistration] = useState({
//     loading: true,
//     isRegistered: null
//   })
//   const [disabled, setDisabled] = useState(null)
//   // const { profile, setProfile, isLoggedIn } = useAuth()
//   // const { loading, isRegistered } = registration

//   const hasClosed = registrationCloseDate
//     ? isPast(new Date(registrationCloseDate))
//     : false

//   const isBrowser = () => typeof window !== "undefined"

//   const lsisRegistered =
//     isBrowser() && JSON.parse(localStorage.getItem(`${id}-isRegistered`))

//   useEffect(() => {
//     // const { loading } = registration

//     if (lsisRegistered) {
//       setRegistration({
//         loading: false,
//         isRegistered: true
//       })
//       setDisabled(true)
//       return
//     }
//     if (loading && profile && profile.email) {
//       isOnList(listId, profile.email).then(response => {
//         if (response.isOnList) {
//           setDisabled(true)
//           localStorage.setItem(`${id}-isRegistered`, JSON.stringify(true))
//         }
//         setRegistration({
//           loading: false,
//           isRegistered: response.isOnList
//         })
//       })
//     }
//   }, [profile])

//   const clickHandler = add => {
//     setDisabled(true)
//     if (add) {
//       addToList(listId, profile.email).then(response => {
//         if (response.data === "Contact Added") {
//           setRegistration({
//             loading: false,
//             isRegistered: true
//           })
//           setDisabled(true)
//           localStorage.setItem(`${id}-isRegistered`, JSON.stringify(true))
//         }
//       })
//     } else {
//       return false
//     }
//   }

//   const getButtonText = () => {
//     if (loading) return "Checking..."
//     if (isRegistered) return "Registered"
//     return "Register Now"
//   }

//   return (
//     <Layout title={title} parent={parent}>
//       <HelmetDatoCms defer={false} />
//       <Block>
//         <Banner image={bannerImage} video={bannerVideo} />
//       </Block>
//       <Block padding="both" guttersMobile maxWidth="inner">
//         <h1>
//           {title}
//           {isRegistered && <Registered />}
//         </h1>
//         <Content html={introNode.childMarkdownRemark.html} />
//         <Block padding="top">
//           {!isRegistered ? (
//             isLoggedIn && profile.isSubscribed && !hasClosed ? (
//               <p>
//                 <Button
//                   type={"button"}
//                   disabled={disabled || loading}
//                   text={getButtonText()}
//                   onClick={() => clickHandler(!isRegistered)}
//                 />
//               </p>
//             ) : (
//               <p>
//                 <Button
//                   type={"button"}
//                   text={hasClosed ? "Closed" : "Loading..."}
//                   disabled={true}
//                 />
//               </p>
//             )
//           ) : (
//             <Grid config={[{ size: "large", columns: "3" }]}>
//               {planner && (
//                 <Button noExternalIcon text="Print Planner" to={planner.url} />
//               )}
//               {booklet && (
//                 <Button
//                   noExternalIcon
//                   text="Download Booklet"
//                   to={booklet.url}
//                 />
//               )}
//               {bookletLink && (
//                 <Button
//                   noExternalIcon
//                   text="Purchase Booklet"
//                   to={bookletLink}
//                 />
//               )}
//             </Grid>
//           )}
//         </Block>
//       </Block>
//       {isRegistered && <LessonList lessons={lessons.nodes} />}
//       <Block padding="both" background="primary-tint">
//         <Block maxWidth="inner" guttersMobile>
//           <Content
//             customOrderedList="simple"
//             html={instructionsNode.childMarkdownRemark.html}
//           />
//         </Block>
//       </Block>
//       {!isRegistered && (
//         <>
//           <Block maxWidth="inner" guttersMobile padding="both">
//             <Image image={image} />
//             <Block padding="top">
//               <Content
//                 styledTable
//                 html={contentNode.childMarkdownRemark.html}
//               />
//             </Block>
//           </Block>
//           <Block padding="both" background="light">
//             <Block gutters>
//               <Grid verticalAlign>
//                 <div>
//                   <h2>{`Meet ${instructor.firstName}`}</h2>
//                   <Content
//                     html={instructor.descriptionNode.childMarkdownRemark.html}
//                     addLastMargin
//                   />
//                 </div>
//                 <Image elevated image={instructor.image} />
//               </Grid>
//             </Block>
//           </Block>
//           <Block gutters padding="both" maxWidth="inner">
//             <Content
//               textCenter
//               html={closingContentNode.childMarkdownRemark.html}
//             />
//           </Block>
//           {!isRegistered && (
//             <Block guttersMobile padding="double-bottom">
//               {isLoggedIn && profile.isSubscribed && !hasClosed ? (
//                 <p>
//                   <Button
//                     type={"button"}
//                     disabled={disabled || loading}
//                     text={getButtonText()}
//                     onClick={() => clickHandler(!isRegistered)}
//                   />
//                 </p>
//               ) : (
//                 <p>
//                   <Button
//                     type={"button"}
//                     text={hasClosed ? "Closed" : "Loading..."}
//                     disabled={true}
//                   />
//                 </p>
//               )}
//             </Block>
//           )}
//         </>
//       )}
//     </Layout>
//   )
// }
// export const query = graphql`
//   query courseById($id: String) {
//     course: datoCmsCourse(id: { eq: $id }) {
//       listId: autopilotListId
//       title
//       id
//       bannerImage {
//         gatsbyImageData(
//           imgixParams: { w: "1280", h: "640", fit: "crop" }
//         )
//       }
//       registrationCloseDate
//       bannerVideo {
//         provider
//         providerUid
//         title
//         thumbnailUrl
//         url
//       }
//       booklet {
//         url
//       }
//       closingContentNode {
//         childMarkdownRemark {
//           html
//         }
//       }
//       contentNode {
//         childMarkdownRemark {
//           html
//         }
//       }
//       courseType
//       image {
//         gatsbyImageData( imgixParams: { fit: "crop", w: "770", h: "460" })
//       }
//       instructionsNode {
//         childMarkdownRemark {
//           html
//         }
//       }
//       instructor {
//         firstName
//         descriptionNode {
//           childMarkdownRemark {
//             html
//           }
//         }
//         image {
//           gatsbyImageData(
//             imgixParams: { fit: "crop", w: "600", h: "490" }
//           )
//         }
//       }
//       introNode {
//         childMarkdownRemark {
//           html
//         }
//       }
//       planner {
//         url
//       }
//       path
//       bookletLink
//       slug
//       title
//     }
//     lessons: allDatoCmsLesson(
//       filter: { course: { id: { eq: $id } } }
//       sort: { fields: startDate, order: ASC }
//     ) {
//       nodes {
//         ...Lesson
//       }
//     }
//   }
// `
// export default Course
